import styles from './styles.module.css';
import Chord from '@/components/Chord';
import useChordStore from '@/store/useChordStore.ts';

const ChordComposer = () => {
  const store = useChordStore();

  return (
    <div className={ styles.chordComposer }>
      <div className={ styles.list }>
        {
          !store.chords?.length ? <span>add chords to get started.</span>
              : store.chords.map((chord, index) =>
                (<Chord
                  key={ chord.id }
                  chord={ chord }
                  onClick={ () => store.removeChordIndex(index) }
                />)
              )
        }
      </div>
    </div>
  );
};

export default ChordComposer;