import { ReactNode, useRef } from 'react';
import styles from './styles.module.css';
import ActionButton from '@/components/ActionButton';
import { BookIcon } from '@/components/Icons';
import useChordStore from '@/store/useChordStore.ts';
import LibraryCard from '@/components/LibraryCard';

type Props = {
    children: ReactNode;
}

const ChordActions = ({ children }: Props) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const addChord = useChordStore(state => state.addChord);

  const handleAddChord = (chord) => {
    addChord(chord);
    popoverRef.current?.togglePopover();
  };

  return (
    <div className={ styles.chordActions }>
      <div className={ styles.content }>
        {children}
      </div>
      <div className={ styles.bottomActions }>
        <ActionButton
          aria-label='chord library'
          icon={ <BookIcon /> }
          popovertarget='library'
          shortcut='^B'
        />
      </div>
      <div
        ref={ popoverRef }
        className={ styles.popover }
        id='library'
        popover='auto'
      ><LibraryCard addChord={ handleAddChord } />
      </div>
    </div>
  );
};

export default ChordActions;