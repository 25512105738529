
import React, { ReactNode } from 'react';
import styles from './styles.module.css';

type Props = React.ComponentPropsWithoutRef<'button'> & {
  icon?: ReactNode;
  shortcut: string;
}
const ActionButton = ({ icon, shortcut, ...props }: Props) => {
  return (
    <button className={ styles.actionButton } { ...props }>
      { icon && <div className={ styles.icon }>{ icon }</div> }
      <kbd>{shortcut}</kbd>
    </button>
  );
};

export default ActionButton;