import GuitarChordComponent from 'react-guitar-chord';
import { MouseEvent } from 'react';
import styles from './styles.module.css';
import { GuitarChord } from '@/types.ts';

type Props = {
  chord: GuitarChord;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
}

export default function Chord({ chord, onClick }: Props) {

  return (
    <div className={ styles.chord } onClick={ (event) => onClick(event) }>
      <h2 className={ styles.title }>{chord.label}</h2>
      <GuitarChordComponent chord={ chord.key } style={ { zIndex: -1 } } />
    </div>
  );
}