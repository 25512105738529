import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import HomePage from '@/pages/Home';

const queryClient = new QueryClient();

export default function App(): ReactNode {
  return (
    <div>
      <QueryClientProvider client={ queryClient }>
        <HomePage />
      </QueryClientProvider>
    </div>
  );
}