import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Chord, GuitarChord } from '@/types.ts';

const chordLabels = [
  'A',
  'A#',
  'B',
  'C',
  'C#',
  'D',
  'D#',
  'E',
  'F',
  'F#',
  'G',
  'G#',
].flatMap((key) => ([
  { label: `${key} Major`, key, suffix: 'Major' },
  { label: `${key} Minor`, key, suffix: 'Minor' }
])).sort((a, b) => a === b ? 0 : a > b ? -1 : 1) as Chord[];

export const getChords = async (query?: string): Promise<Chord[]> => {
  if (!query) {
    return chordLabels;
  }

  return chordLabels.filter(({ label }) => label.toLowerCase().includes(query.toLowerCase()));
};

export const useChord = (query: string) => {
  return useQuery({
    queryKey: ['chords', query],
    queryFn: () => getChords(query)
  });
};