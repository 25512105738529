import React from 'react';
import ReactDOM from 'react-dom/client';
import { Theme } from '@radix-ui/themes';
import App from './App.tsx';
import '@radix-ui/themes/styles.css';
import './index.css';

const root = document.getElementById('root')!;

if (!Object.prototype.hasOwnProperty.call(HTMLElement.prototype, 'popover')) {
  root.innerText = 'This site uses some cutting edge tech and the dev has decided not to make it backwards compatible. You will need to use a modern browser to continue.';
} else {
  ReactDOM.createRoot(root).render(
    // <React.StrictMode>
    <Theme>
      <App />
    </Theme>
    // </React.StrictMode>,
  );
}


