import { useCallback, useEffect, useRef, useState } from 'react';
import { c } from 'vite/dist/node/types.d-aGj9QkWt';
import styles from './styles.module.css';
import { SearchIcon } from '@/components/Icons';
import { getChords, useChord } from '@/utilities/chords.ts';
import Chord from '@/components/Chord';
import useChordStore from '@/store/useChordStore.ts';

interface Props {
    addChord: (chord: Chord) => void;
}

const LibraryCard = ({ addChord }: Props) => {
  const [query, setQuery] = useState<string>('');
  const chordsQuery = useChord(query);
  const mainRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [virtualFocus, setVirtualFocus] = useState<number>(0);


  return (
    <div ref={ mainRef } className={ styles.libraryCard }>
      <header>
        <form className={ styles.search }>
          <label className={ styles.searchLabel } htmlFor='search-input'><SearchIcon
            aria-hidden='true' /></label>
          <input
            ref={ inputRef }
            className={ styles.searchInput }
            id='search-input'
            maxLength='64'
            type='text'
            value={ query }
            onInput={ (event) => setQuery(event.currentTarget.value) }
          />
        </form>
      </header>
      <section className={ styles.results }>
        {
          chordsQuery.isLoading ? (
              <p>Loading...</p>
          ) : (
              <ul className={ styles.chords }>
                {chordsQuery.data.map((chord) => (
                  <li key={ chord.id } className={ styles.chordAction }>
                    <button onClick={ () => {
                      addChord(chord);
                    } }>{chord.label}</button>
                  </li>
                ))}
              </ul>
          )
        }
      </section>
    </div>
  );
};

export default LibraryCard;