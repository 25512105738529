import { create } from 'zustand';
import { GuitarChord } from '@/types.ts';

const dummy: (GuitarChord & { id: string })[] = [
];

const useChordStore = create((set) => ({
  chords: [...dummy],
  addChord(chord) {
    set((state) => ({ chords: [...state.chords, chord] }));
  },
  removeChordIndex(index: number) {
    set((state) => {
      const chords = [...state.chords];
      chords.splice(index, 1);
      return { chords };
    });
  },
  addRandomChord() {
    set((state) => ({ chords: [...state.chords, { id: self.crypto.randomUUID(), key: 'A', label: 'A major' }] }));
  }
}));

export default useChordStore;